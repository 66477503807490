//#region Imports

import { IAuthInfoDto, IUserInfoV1Dto, Oauth2Service } from '@abcfinlab/api/login';
import { AppConfig, SESSION_AVAILABLE_TOKEN } from '@abcfinlab/core';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of, throwError, timer } from 'rxjs';
import { catchError, delayWhen, map, retryWhen, take, tap } from 'rxjs/operators';
import { AUTH_MODULE_CONFIG } from '../Modules/AuthModuleTokens';
import { IAuthModuleConfig } from '../Modules/IAuthModuleConfig';

//#endregion

@Injectable()
export class AuthService {

    //#region Fields

    private readonly _oauth2Service: Oauth2Service;
    private readonly _sessionAvailable: Subject<void>;
    private readonly _moduleConfig: IAuthModuleConfig;
    private readonly _hasSessionSubject: BehaviorSubject<boolean>;

    private _isSessionAvailable: boolean;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `AuthService` class.
     *
     * @public
     */
    public constructor(http: HttpClient, appConfig: AppConfig, oauth2Service: Oauth2Service,
        @Inject(SESSION_AVAILABLE_TOKEN) sessionAvailable: Subject<never>,
        @Inject(AUTH_MODULE_CONFIG) moduleConfig: IAuthModuleConfig) {
        this._oauth2Service = oauth2Service;
        this._sessionAvailable = sessionAvailable;
        this._moduleConfig = moduleConfig;

        this._isSessionAvailable = false;
        this._hasSessionSubject = new BehaviorSubject<boolean>(false);
    }

    //#endregion

    /**
     * Returns the `isLoggedIn` property.
     *
     * @public
     * @readonly
     */
    public get hasSession(): Observable<boolean> {
        return this._hasSessionSubject.asObservable();
    }

    //#region Methods

    /**
     * @public
     */
    public getSession(): Observable<never> {
        return this._oauth2Service.sessionInfo().pipe(
            tap(() => {
                this._hasSessionSubject.next(true);
                if (!this._isSessionAvailable) {
                    this._isSessionAvailable = true;
                    this._sessionAvailable.next();
                }
            }),
            catchError(err => {
                this._hasSessionSubject.next(false);
                return throwError(err);
            })
        );
    }

    /**
     * @public
     */
    public removeSession(): void {
        this._isSessionAvailable = false;
        this._hasSessionSubject.next(false);
    }

    /**
     * @public
     */
    public callback(code: string, realm: string): Observable<IUserInfoV1Dto> {
        return this._oauth2Service.callback({
            code: code,
            realm: realm
        }).pipe(
            tap(() => {
                this._hasSessionSubject.next(true);
                if (!this._isSessionAvailable) {
                    this._isSessionAvailable = true;
                    this._sessionAvailable.next();
                }
            }),
            catchError(err => {
                this._hasSessionSubject.next(false);
                return throwError(err);
            })
        );
    }

    /**
     * @public
     */
    public auth(userName: string): Observable<Array<IAuthInfoDto>> {
        return this._oauth2Service.oauth2auth({
            username: userName,
        }).pipe(
            map((x) => {
                if (x.authInfos) {
                    return x.authInfos;
                }

                return [];
            }),
            catchError(error => {
                return of([]);
            })
        );
    }

    /**
     * @public
     */
    public logout(): Observable<any> {
        return this._oauth2Service.logout().pipe(
            map(() => {
                this._hasSessionSubject.next(false);
                return of(true);
            }),
            catchError(err => {
                return throwError(err);
            }),
            retryWhen(err => err.pipe(
                delayWhen(() => timer(this._moduleConfig.logoutRetryTimeout)),
                take(this._moduleConfig.logoutRetryAttempts)))
        );
    }

    //#endregion

}
