//#region Imports

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { GcpSettingsService } from '@abcfinlab/api/login';

//#endregion

@Injectable({
    providedIn: 'root'
})
export class UiStatesPreservationService {

    //#region Fields

    private readonly _settingsService: GcpSettingsService;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `UiStatesPreservationService` class.
     *
     * @public
     */
    public constructor(settingsService: GcpSettingsService) {
        this._settingsService = settingsService;
    }

    //#endregion

    //#region Methods

    /**
     * @public
     */
    public get<T>(key: string): Observable<NonNullable<T> | null> {
        return this._settingsService.getSetting({ key: key }).pipe(
            map(x => x.value !== null && x.value !== undefined && x.value !== ''
                ? JSON.parse(x.value)
                : null)
        );
    }

    /**
     * @public
     */
    public set(key: string, value: Record<string, any>): Observable<void> {
        return this.handle(this._settingsService.createOrUpdateSetting({
            body: {
                key: key,
                value: JSON.stringify(value)
            }
        }));
    }

    /**
     * @public
     */
    public remove(key: string): Observable<void> {
        return this.handle(this._settingsService.deleteSetting({ key: key }));
    }

    /**
     * @private
     */
    private handle(operation: Observable<any>): Observable<any> {
        const subject = new Subject<void>();
        const subscription = operation.subscribe(() => {
            subscription.unsubscribe();
            subject.next();
            subject.complete();
        });

        return subject.asObservable();
    }

    //#endregion

}
