//#region Imports

import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

//#endregion

/**
 * @public
 */
@Injectable({ providedIn: 'root' })
export class ToastService {

    //#region Fields

    private readonly _snackbar: MatSnackBar;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `ToastService` class.
     *
     * @public
     */
    public constructor(snackbar: MatSnackBar) {
        this._snackbar = snackbar;
    }

    //#endregion

    //#region Methods

    /**
     * @public
     */
    public show(message: string | Observable<string>, type: 'primary' | 'success' | 'warning' | 'danger', duration?: 'short' | 'long'): MatSnackBarRef<TextOnlySnackBar> {
        if (typeof message === 'string') {
            return this._snackbar.open(message, undefined, {
                panelClass: type,
                duration: duration === 'long' ? 6000 : 3000
            });
        }

        const sub = message.pipe(
            finalize(() => {
                sub.unsubscribe();
            })
        ).subscribe(x => this._snackbar.open(x, undefined, {
            panelClass: type,
            duration: duration === 'long' ? 6000 : 3000
        }));
    }

    /**
     * @public
     */
    public showSuccess(message: string, duration?: 'short' | 'long'): MatSnackBarRef<TextOnlySnackBar> {
        return this.show(message, 'success', duration);
    }

    /**
     * @public
     */
    public showWarning(message: string, duration?: 'short' | 'long'): MatSnackBarRef<TextOnlySnackBar> {
        return this.show(message, 'warning', duration);
    }

    /**
     * @public
     */
    public showError(message: string, duration?: 'short' | 'long'): MatSnackBarRef<TextOnlySnackBar> {
        return this.show(message, 'danger', duration);
    }

    /**
     * @public
     */
    public showPrimary(message: string, duration?: 'short' | 'long'): MatSnackBarRef<TextOnlySnackBar> {
        return this.show(message, 'primary', duration);
    }


    //#endregion

}
