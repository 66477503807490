import { ContactService, IContactDto, IContactSearchResultEntryDto, IIdTypeDto } from '@abcfinlab/api/contact';
import {
    ICalculationSettingsDto,
    IContractTypeDto,
    IHandlingFeeOptionDto,
    ILesseeUpdateForQuoteDraftDto,
    IObjectConditionDto,
    IRetailerCalculationRequestDto,
    IRetailerCalculationResultDto,
    IRetailerCreateDraftDto,
    IRetailerCreateQuoteRequestDto,
    IRetailerObjectGroupDto, IRetailerQuoteDto,
    IRetailerQuoteResultDto,
    IRetailerQuoteSettingsResponseDto,
    RetailerLeasingService,
    RetailerQuoteService,
} from '@abcfinlab/api/global';
import { IRetailerResponseDto } from '@abcfinlab/api/retailer';
import { ISaveOfferWithoutCrefoViewDialogData, ISaveOfferWithoutCrefoViewDialogResult, SaveOfferWithoutCrefoView } from '@abcfinlab/contacts';
import { ControlsOf, Validators as CoreValidators, FormValidator, Globals, once, TranslationFacade } from '@abcfinlab/core';
import { objectToSnake } from '@abcfinlab/presentation';
import { BusyBoxService, MessageBoxButton, MessageBoxResult, MessageBoxService, ToastService, WizardSelectionChangeEvent } from '@abcfinlab/ui';
import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Injectable, TrackByFunction } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription, throwError } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { getPercent, getPercentageValue, toDecimal } from '../../../../../apps/shell/src/app/helper/calculation.helper';
import { QUOTE_RETAILER_QUOTE_DETAILS_ROUTE_PATH } from '../../Routing/RoutePaths';
import { Validators as QuoteValidators } from '../../Validators/Validators';
import { IUpdateCalculationInfo } from '../retailer-update-lessee-overview/retailer-update-lessee-overview-presenter';

interface IFirstStepFormDto {
    calculation: {
        // object
        objectName: FormControl<string>;
        objectQuantity: FormControl<number>;

        data: {
            totalLeasingValue: FormControl<number>;
            contractType: FormControl<'TA' | 'IT_FLEX_SMART' | 'VA'>;
            objectGroups: FormControl<IRetailerObjectGroupDto>;
            condition: FormControl<IObjectConditionDto>;
            residualValue: FormControl<number>; // only in contractType.TA
            residualValueInPercent: FormControl<number>; // only in contractType.TA
            downPaymentCheckBox: FormControl<boolean>;
            downPayment: FormControl<number>;
            downPaymentInPercent: FormControl<number>;
            expert: {
                // expert
                dealerCommission: FormControl<boolean>;
                dealerCommissionInPercent: FormControl<number>;
                insurance: FormControl<boolean>;
                insuranceValue: FormControl<number>;
                handlingFee: FormControl<boolean>;
                handlingFeeValue: FormControl<number>;
            };
        };
        exclusionOfWarranty: FormControl<boolean>; // only in condition.Used
        rate: FormControl<IRetailerCalculationResultDto>;
    };
    search: {
        companyName: FormControl<string>;
        city: FormControl<string>;
        country: FormControl<string>;
    };
    lessee: FormControl<Array<IContactSearchResultEntryDto>>;
}

/**
 * The presenter of the {@link RetailerCalculationView} view.
 *
 * @internal
 */
@UntilDestroy()
@Injectable()
export class RetailerCalculationViewPresenter {
    // #region Fields

    private readonly _retailerCalculationService: RetailerLeasingService;
    private readonly _retailerQuoteService: RetailerQuoteService;
    private readonly _contactService: ContactService;
    private readonly _router: Router;
    private readonly _location: Location;
    private readonly _dialogService: MatDialog;
    private readonly _activatedRoute: ActivatedRoute;
    private readonly _busyBoxService: BusyBoxService;
    private readonly _toastService: ToastService;
    private readonly _messageBoxService: MessageBoxService;
    private readonly _translationFacade: TranslationFacade;
    private readonly _formValidator: FormValidator;
    private readonly _cdr: ChangeDetectorRef;
    private readonly _retailerSettingsSubject = new BehaviorSubject<IRetailerQuoteSettingsResponseDto>(null);
    private readonly _retailerInfoSubject = new BehaviorSubject<IRetailerResponseDto>(null);
    private readonly _retailerContractTypesSubject = new BehaviorSubject<Array<'TA' | 'IT_FLEX_SMART' | 'VA'>>(null);
    private readonly _retailerObjectGroupsSubject = new BehaviorSubject<Array<IRetailerObjectGroupDto>>(null);
    private readonly _ratesSubject = new BehaviorSubject<Array<IRetailerCalculationResultDto>>(null);
    private readonly _showRatesSubject = new BehaviorSubject<boolean>(false);
    private readonly _showResidualValuesSubject = new BehaviorSubject<boolean>(false);
    private readonly _isNewVersionSubject = new BehaviorSubject<boolean>(false);
    private readonly _minAndMaxDownPaymentValuesSubject = new BehaviorSubject<{ min: number; max: number }>(null);
    private readonly _minAndMaxResidualValuesSubject = new BehaviorSubject<{ min: number; max: number }>(null);
    private readonly _minAndMaxDealerCommissionValuesSubject = new BehaviorSubject<{ min: number; max: number }>(null);
    private readonly _showExclusionOfWarrantySubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly _lessees: BehaviorSubject<Array<IContactSearchResultEntryDto>> = new BehaviorSubject<Array<IContactSearchResultEntryDto>>(null);
    private readonly _calculationDetails: BehaviorSubject<IRetailerCalculationRequestDto> = new BehaviorSubject<IRetailerCalculationRequestDto>(null);
    private readonly _quoteCalculationSettings: BehaviorSubject<ICalculationSettingsDto> = new BehaviorSubject<ICalculationSettingsDto>(null);
    private readonly _trackByIndex: TrackByFunction<number>;
    private readonly _selectedStepSubject: BehaviorSubject<number>;
    private readonly _hasDownPaymentSubject: BehaviorSubject<boolean>;
    private readonly _residualDissolvedFactorSubject: BehaviorSubject<null | 'currency' | 'percent'>;

    private _form: FormGroup<ControlsOf<IFirstStepFormDto>>;
    private _activatedRouteSubscription: Subscription;
    private _officeServiceEmail: string;
    private _retailerCompany: string;
    private _quoteId: string;
    private _lesseeId: string;
    private _isRecalculation: boolean;
    private _updateExistingCalculationInfo: IUpdateCalculationInfo;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `RetailerCalculationViewPresenter` class.
     *
     * @public
     */
    public constructor(activatedRoute: ActivatedRoute, retailerCalculationService: RetailerLeasingService,
        busyBoxService: BusyBoxService, toastService: ToastService, translationFacade: TranslationFacade,
        contactService: ContactService, messageBoxService: MessageBoxService, router: Router,
        retailerQuoteService: RetailerQuoteService,
        formValidator: FormValidator, cdr: ChangeDetectorRef, dialogService: MatDialog, location: Location,
    ) {
        this._activatedRoute = activatedRoute;
        this._retailerCalculationService = retailerCalculationService;
        this._retailerQuoteService = retailerQuoteService;
        this._contactService = contactService;
        this._router = router;
        this._activatedRoute = activatedRoute;
        this._busyBoxService = busyBoxService;
        this._toastService = toastService;
        this._messageBoxService = messageBoxService;
        this._translationFacade = translationFacade;
        this._formValidator = formValidator;
        this._cdr = cdr;
        this._location = location;
        this._dialogService = dialogService;
        this._trackByIndex = (index: number): number => index;
        this._selectedStepSubject = new BehaviorSubject(0);
        this._hasDownPaymentSubject = new BehaviorSubject(false);
        this._residualDissolvedFactorSubject = new BehaviorSubject(null);
    }

    // #endregion

    // #region Properties

    /**
     * Returns the `form` property.
     *
     * @public
     * @readonly
     */
    public get form(): FormGroup<ControlsOf<IFirstStepFormDto>> {
        return this._form;
    }

    /**
     * Returns the `selectedStep` property.
     *
     * @public
     * @readonly
     */
    public get selectedStep(): Observable<number> {
        return this._selectedStepSubject.asObservable();
    }

    public get isRecalculation(): boolean {
        return this._isRecalculation;
    }

    /**
     * Returns the `residualDissolvedFactor` property.
     *
     * @public
     * @readonly
     */
    public get residualDissolvedFactor(): Observable<null | 'currency' | 'percent'> {
        return this._residualDissolvedFactorSubject.asObservable();
    }

    /**
     * Returns the `hasDownPayment` property.
     *
     * @public
     * @readonly
     */
    public get hasDownPayment(): Observable<boolean> {
        return this._hasDownPaymentSubject.asObservable();
    }

    /**
     * Returns the `officeServiceEmail` property.
     *
     * @public
     * @readonly
     */
    public get officeServiceEmail(): string {
        return this._officeServiceEmail;
    }

    /**
     * Returns the `retailerCompany` property.
     *
     * @public
     * @readonly
     */
    public get retailerCompany(): string {
        return this._retailerCompany;
    }

    /**
     * Returns the `retailerSettings` property.
     *
     * @public
     * @readonly
     */
    public get retailerSettings(): Observable<IRetailerQuoteSettingsResponseDto> {
        return this._retailerSettingsSubject.asObservable();
    }

    /**
     * Returns the `retailerContractTypes` property.
     *
     * @public
     * @readonly
     */
    public get retailerContractTypes(): Observable<Array<'TA' | 'IT_FLEX_SMART' | 'VA'>> {
        return this._retailerContractTypesSubject.asObservable();
    }

    /**
     * Returns the `retailerObjectGroups` property.
     *
     * @public
     * @readonly
     */
    public get retailerObjectGroups(): Observable<Array<IRetailerObjectGroupDto>> {
        return this._retailerObjectGroupsSubject.asObservable();
    }

    /**
     * Returns the `rates` property.
     *
     * @public
     * @readonly
     */
    public get rates(): Observable<Array<IRetailerCalculationResultDto>> {
        return this._ratesSubject.asObservable();
    }

    /**
     * Returns the `showRates` property.
     *
     * @public
     * @readonly
     */
    public get showRates(): Observable<boolean> {
        return this._showRatesSubject.asObservable();
    }

    /**
     * Returns the `isNewVersionSubject` property.
     *
     * @public
     * @readonly
     */
    public get isNewVersionSubject(): Observable<boolean> {
        return this._isNewVersionSubject.asObservable();
    }

    /**
     * Returns the `showResidualValues` property.
     *
     * @public
     * @readonly
     */
    public get showResidualValues(): Observable<boolean> {
        return this._showResidualValuesSubject.asObservable();
    }

    /**
     * Returns the `showExclusionOfWarranty` property.
     *
     * @public
     * @readonly
     */
    public get showExclusionOfWarranty(): Observable<boolean> {
        return this._showExclusionOfWarrantySubject.asObservable();
    }

    /**
     * Returns the `minAndMaxResidualValues` property.
     *
     * @public
     * @readonly
     */
    public get minAndMaxResidualValues(): Observable<{ min: number; max: number }> {
        return this._minAndMaxResidualValuesSubject.asObservable();
    }

    /**
     * Returns the `minAndMaxDownPaymentValues` property.
     *
     * @public
     * @readonly
     */
    public get minAndMaxDownPaymentValues(): Observable<{ min: number; max: number }> {
        return this._minAndMaxDownPaymentValuesSubject.asObservable();
    }

    /**
     * Returns the `minAndMaxDealerCommissionValues` property.
     *
     * @public
     * @readonly
     */
    public get minAndMaxDealerCommissionValues(): Observable<{ min: number; max: number }> {
        return this._minAndMaxDealerCommissionValuesSubject.asObservable();
    }

    /**
     * Returns the `lessees` property.
     *
     * @public
     * @readonly
     */
    public get lessees(): Observable<Array<IContactSearchResultEntryDto>> {
        return this._lessees.asObservable();
    }

    public get quoteCalculationSettings(): Observable<ICalculationSettingsDto> {
        return this._quoteCalculationSettings.asObservable();
    }

    public get trackByIndex(): TrackByFunction<number> {
        return this._trackByIndex;
    }

    // #endregion

    // #region Methods

    /**
     * Called before the view first displays the data-bound properties and sets the view's input properties.
     *
     * @internal
     */
    public initialize(): void {
        // @ts-ignore: we need like this to get the new calculation info and as location state does not have typing, we ignore it
        this._updateExistingCalculationInfo = this._location.getState();
        this._isRecalculation = this._updateExistingCalculationInfo.isRecalculation ?? false;
        this._activatedRouteSubscription = combineLatest([this._activatedRoute.data, this._activatedRoute.url, this._activatedRoute.params])
            .subscribe(([data, urlSegments, params]) => {
                if (data.calculationSettings) {
                    this._quoteCalculationSettings.next(data.calculationSettings);
                    this.initializeForm();
                }
                if (data.retailerSettings) {
                    const retailerSettings: IRetailerQuoteSettingsResponseDto = data.retailerSettings[0];
                    const retailerInfo: IRetailerResponseDto = data.retailerSettings[1];
                    this._retailerSettingsSubject.next(retailerSettings);
                    this._retailerInfoSubject.next(retailerInfo);
                    this._retailerCompany = retailerInfo.retailer.name;
                    this._officeServiceEmail = retailerInfo.contact.officeService.email;
                    this._retailerContractTypesSubject.next(retailerSettings.contractType);
                    this._retailerObjectGroupsSubject.next(retailerSettings.objectGroups);
                    this._form.controls.calculation.controls.data.controls.expert.controls.insuranceValue.patchValue(0, { emitEvent: false });
                    this._form.controls.calculation.controls.data.controls.expert.controls.handlingFeeValue.patchValue(retailerSettings.handlingFeeValue, { emitEvent: false });
                    this._form.controls.calculation.controls.data.controls.expert.controls.dealerCommissionInPercent.patchValue(retailerSettings.provisionInPercent, { emitEvent: false });

                    if (!retailerSettings.provision) {
                        this._form.controls.calculation.controls.data.controls.expert.controls.dealerCommissionInPercent.disable();
                    }

                    if (retailerSettings.objectGroups.length === 1) {
                        this._form.controls.calculation.controls.data.controls.objectGroups.patchValue(retailerSettings.objectGroups[0]);
                    }

                    if (retailerSettings.contractType.length === 1) {
                        this._form.controls.calculation.controls.data.controls.contractType.patchValue(retailerSettings.contractType[0]);
                    }

                    if (retailerSettings.contractType.length === 1 && retailerSettings.contractType[0] === IContractTypeDto.Ta) {
                        this.addValidatorRequiredToFormControl(this._form.controls.calculation.controls.data.controls.residualValue);
                        this.addValidatorRequiredToFormControl(this._form.controls.calculation.controls.data.controls.expert.controls.dealerCommissionInPercent);
                        this._showResidualValuesSubject.next(true);
                    }

                    if (retailerSettings.handlingFeeOption === IHandlingFeeOptionDto.AlwaysCharge) {
                        this._form.controls.calculation.controls.data.controls.expert.controls.handlingFee.disable();
                    } else if (retailerSettings.handlingFeeOption === IHandlingFeeOptionDto.NeverCharge) {
                        this._form.controls.calculation.controls.data.controls.expert.controls.handlingFee.patchValue(false);
                        this._form.controls.calculation.controls.data.controls.expert.controls.handlingFee.disable();
                        this.removeValidatorRequiredToFormControl(this._form.controls.calculation.controls.data.controls.expert.controls.handlingFee);
                    } else if (retailerSettings.handlingFeeOption === IHandlingFeeOptionDto.Customizable) {
                        this.removeValidatorRequiredToFormControl(this._form.controls.calculation.controls.data.controls.expert.controls.handlingFee);
                        this.removeValidatorRequiredToFormControl(this._form.controls.calculation.controls.data.controls.expert.controls.handlingFeeValue);
                    }

                    const newVersion = urlSegments.find(seg => seg.path === 'new-version');
                    if (newVersion) {
                        this._quoteId = params.id;
                        this._isNewVersionSubject.next(true);
                        this._lesseeId = data.quoteDetails.quote.lesseeId;
                        this.patchValuesFromQuote(data.quoteDetails);
                    }
                }
            });
    }

    /**
     * Called before the view will be destroyed.
     * Unsubscribe Observables and detach event handlers to avoid memory leaks.
     *
     * @internal
     */
    public dispose(): void {
        this._activatedRouteSubscription.unsubscribe();
    }

    public createQuote(): Observable<string> {
        const subject = new Subject<string>();
        const subjectDoneFn = (query: unknown, _data?: unknown): void => {
            subject.next(btoa(JSON.stringify(query)));
            subject.complete();
        };

        let downPaymentInPercent = null;
        let residualValueInPercent = null;
        if (this._form.controls.calculation.controls.data.controls.downPaymentCheckBox.value) {
            downPaymentInPercent = this.calculatePercentageValue('downPayment');
        }
        if (this._form.controls.calculation.controls.data.controls.contractType.value === IContractTypeDto.Ta) {
            residualValueInPercent = this.calculatePercentageValue('residualValue');
        }

        const formData: IRetailerCreateQuoteRequestDto = {
            ...{
                calculationDetails: {
                    ...this._calculationDetails.getValue(),
                    ...{
                        monthlyInsurance: this._form.controls.calculation.controls.data.controls.expert.controls.insurance.getRawValue(),
                        handlingFee: this._form.controls.calculation.controls.data.controls.expert.controls.handlingFee.getRawValue(),
                        dealerCommissionInPercent: this._form.controls.calculation.controls.data.controls.expert.controls.dealerCommission.getRawValue() ? this._form.controls.calculation.controls.data.controls.expert.controls.dealerCommissionInPercent.getRawValue() : null,
                        downPaymentInPercent,
                        residualValueInPercent,
                    },
                },
                calculationSelection: this._form.controls.calculation.controls.rate.getRawValue(),
                lesseeContact: this._form.controls.lessee.getRawValue().at(0) as unknown as IRetailerCreateQuoteRequestDto['lesseeContact'],
                quoteId: this._quoteId,
                exclusionOfWarranty: this._form.controls.calculation.controls.exclusionOfWarranty.getRawValue(),
                objectQuantity: this._form.controls.calculation.controls.objectQuantity.getRawValue(),
                objectName: this._form.controls.calculation.controls.objectName.getRawValue(),
            },
            ...{ exclusionOfWarranty: this._form.controls.calculation.controls.data.controls.condition.getRawValue() === IObjectConditionDto.New ? false : this._form.controls.calculation.controls.exclusionOfWarranty.getRawValue() },
        };

        this._busyBoxService.show(null, this._translationFacade.translate('global.busy'), this._retailerQuoteService.createRetailerLeasingQuote({ body: formData }))
            .subscribe((result) => {
                subjectDoneFn(formData, result);
                const quoteDetailsRoute = this._isNewVersionSubject.getValue() ? `../../../../../${QUOTE_RETAILER_QUOTE_DETAILS_ROUTE_PATH}` : `../../../${QUOTE_RETAILER_QUOTE_DETAILS_ROUTE_PATH}`;
                void this._router.navigate([quoteDetailsRoute, result.quoteId], { relativeTo: this._activatedRoute });
            }, () => {
                this._toastService.show(this._translationFacade.translate('error.generic_error'), 'danger');
            });

        return subject.asObservable();
    }

    public onRequestMail(): void {
        this._dialogService.open<SaveOfferWithoutCrefoView, ISaveOfferWithoutCrefoViewDialogData, ISaveOfferWithoutCrefoViewDialogResult>(SaveOfferWithoutCrefoView, {
            width: '600px',
            minHeight: '200px',
            data: this._form.controls.search.getRawValue(),
        }).afterClosed().subscribe((confirmed) => {
            if (confirmed) {
                const data: IRetailerCreateDraftDto = {
                    warrantyRequired: this._form.controls.calculation.controls.exclusionOfWarranty.value,
                    objectName: this._form.controls.calculation.controls.objectName.value,
                    objectQuantity: this._form.controls.calculation.controls.objectQuantity.value,
                    quoteId: crypto.randomUUID(),
                    retailerCalculationRequest: this._calculationDetails.value,
                    retailerCalculationResult: this._form.controls.calculation.controls.rate.value,
                    lesseeContact: {
                        city: confirmed.city,
                        country: confirmed.country,
                        firstName: confirmed.companyName,
                        houseNumber: confirmed.houseNumber,
                        name: confirmed.companyName,
                        name2: confirmed.companyName,
                        postcode: confirmed.postalCode,
                        street: confirmed.street,
                    },
                };
                this._busyBoxService.show(null, this._translationFacade.translate('global.busy'), this._retailerQuoteService.createQuoteDraft({ body: data })).subscribe((result) => {
                    const quoteDetailsRoute = this._isNewVersionSubject.getValue() ? `../../../../../${QUOTE_RETAILER_QUOTE_DETAILS_ROUTE_PATH}` : `../../../${QUOTE_RETAILER_QUOTE_DETAILS_ROUTE_PATH}`;
                    void this._router.navigate([quoteDetailsRoute, result.quoteId], { relativeTo: this._activatedRoute });
                    this._toastService.show(this._translationFacade.translate('quote.draftCreated'), 'success');
                }, () => {
                    this._toastService.show(this._translationFacade.translate('error.generic_error'), 'danger');
                });
            }
        });
    }

    public updateCalculation(): void {
        const newCalculation: IRetailerQuoteDto = this._updateExistingCalculationInfo.newCalculation;

        newCalculation.type = this._form.controls.calculation.controls.data.controls.contractType.value;
        newCalculation.objects[0].objectQuantity = this._form.controls.calculation.controls.objectQuantity.value;
        newCalculation.objects[0].objectDescription = this._form.controls.calculation.controls.objectName.value;
        newCalculation.objects[0].exclusionOfWarranty = this._form.controls.calculation.controls.exclusionOfWarranty.value;
        newCalculation.objects[0].condition = this._form.controls.calculation.controls.data.controls.condition.value;
        newCalculation.monthlyInsuranceValue = this._form.controls.calculation.controls.data.controls.expert.controls.insuranceValue.value;
        newCalculation.handlingFeeValue = this._form.controls.calculation.controls.data.controls.expert.controls.handlingFeeValue.value;
        newCalculation.dealerCommissionInPercent = this._form.controls.calculation.controls.data.controls.expert.controls.dealerCommissionInPercent.value;
        newCalculation.calculation.downPaymentValue = this._form.controls.calculation.controls.data.controls.downPayment.value;
        newCalculation.calculation.terms = this._form.controls.calculation.controls.rate.value.terms;
        newCalculation.calculation.instalment = this._form.controls.calculation.controls.rate.value.instalment;
        newCalculation.calculation.residualValue = this._form.controls.calculation.controls.data.controls.residualValue.value;
        newCalculation.totalLeasingValue = this._form.controls.calculation.controls.data.controls.totalLeasingValue.value;
        newCalculation.objects[0].objectGroup = this._form.controls.calculation.controls.data.controls.objectGroups.value.code;
        newCalculation.quoteId = this._updateExistingCalculationInfo.quoteId;
        const updateLesseeInfo: ILesseeUpdateForQuoteDraftDto = {
            lesseeContact: this._updateExistingCalculationInfo.lesseeContact,
            newLeasingQuote: newCalculation,
        };
        once(this._busyBoxService.show(undefined, this._translationFacade.translate('global.busy'),
            this._retailerQuoteService.updateDraftWithLessee({ leasingQuoteId: this._updateExistingCalculationInfo.quoteId, body: updateLesseeInfo }).pipe(),
        ), () => {
            void this._router.navigateByUrl(`presentation/${QUOTE_RETAILER_QUOTE_DETAILS_ROUTE_PATH}/${String(this._updateExistingCalculationInfo.quoteId)}`);
        }, () => {
            this._toastService.show(this._translationFacade.translate('error.generic_error'), 'danger');
        });
    }

    public onStepChanged(event: WizardSelectionChangeEvent): void {
        const { selectedIndex } = event;

        // if the user whants to go next, validate the form

        // #1 is the general calculation
        if (selectedIndex === 1) {
            this._formValidator
                .validate(this._form.controls.calculation);

            if (this._formValidator.errors(this._form.controls.calculation).length > 0) {
                console.error(this._formValidator.errors(this._form.controls.calculation).map(x => x.name).join(':'));
                this._toastService.show(this._translationFacade.translate('global.pleaseCheckData'), 'danger');
                event.cancel = true;
                return;
            }
            if (this._isNewVersionSubject.getValue()) {
                this._contactService.getById({ id: this._lesseeId, idType: IIdTypeDto.Id })
                    .subscribe((lessee) => {
                        const updatedLessee = {
                            ...lessee,
                            crefoId: lessee.crefo_id,
                        };
                        delete updatedLessee.crefo_id;
                        this._form.controls.lessee.patchValue([updatedLessee as any], { emitEvent: true });
                    });
                event.cancel = true;
            } else {
                this._selectedStepSubject.next(selectedIndex);
            }
        }

        // #2 is the search for the lessee
        if (selectedIndex === 2) {
            this._formValidator
                .validate(this._form.controls.search);

            if (this._formValidator.errors(this._form.controls.search).length > 0) {
                console.error(this._formValidator.errors(this._form.controls.search).map(x => x.name).join(':'));
                this._toastService.show(this._translationFacade.translate('global.pleaseCheckData'), 'danger');
                event.cancel = true;
                return;
            }

            this._busyBoxService.show(null, this._translationFacade.translate('global.busy'), this._contactService.searchCamLegacy(this._form.controls.search.getRawValue())).subscribe((result) => {
                this._lessees.next(result.content);
                this._selectedStepSubject.next(selectedIndex);
            }, () => {
                this._toastService.show(this._translationFacade.translate('error.generic_error'), 'danger');
            });
        }

        // #3 is the lessee selection
        if (selectedIndex === 3) {
            this._formValidator
                .validate(this._form.controls.lessee);

            if (this._formValidator.errors(this._form.controls.lessee).length > 0) {
                this._toastService.show(this._formValidator.errors(this._form.controls.lessee).map(x => x.name).join(':'), 'danger');
                event.cancel = true;
                return;
            }

            this._selectedStepSubject.next(selectedIndex);
        }
    }

    private initializeForm(): void {
        const quoteCalculationProperties = this._quoteCalculationSettings.value.leasingQuoteCalculationProperties;

        this._form = new FormGroup<ControlsOf<IFirstStepFormDto>>({
            calculation: new FormGroup({
                objectName: new FormControl<string>(null, [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
                objectQuantity: new FormControl<number>(1, Validators.required),
                data: new FormGroup({
                    totalLeasingValue: new FormControl<number>(null, [Validators.required, QuoteValidators.allowedRange(quoteCalculationProperties.retailer.maxObjectValue, quoteCalculationProperties.retailer.minObjectValue)]),
                    contractType: new FormControl<'TA' | 'IT_FLEX_SMART' | 'VA'>(null, Validators.required),
                    objectGroups: new FormControl<IRetailerObjectGroupDto>(null, Validators.required),
                    condition: new FormControl<IObjectConditionDto>(IObjectConditionDto.New, Validators.required),
                    residualValue: new FormControl<number>(null),
                    residualValueInPercent: new FormControl<number>(null),
                    downPaymentCheckBox: new FormControl<boolean>(false),
                    downPayment: new FormControl<number>(null),
                    downPaymentInPercent: new FormControl<number>(null),
                    expert: new FormGroup({
                        dealerCommission: new FormControl<boolean>(true, Validators.required),
                        dealerCommissionInPercent: new FormControl<number>(null, [Validators.required, CoreValidators.isNumberInRange(0, 0.05, 100)]),
                        insurance: new FormControl<boolean>(true, Validators.required),
                        insuranceValue: new FormControl<number>({ value: null, disabled: true }, Validators.required),
                        handlingFee: new FormControl<boolean>(true, Validators.required),
                        handlingFeeValue: new FormControl<number>({ value: null, disabled: true }, Validators.required),
                    }),
                }),
                exclusionOfWarranty: new FormControl<boolean>(true),
                rate: new FormControl<IRetailerCalculationResultDto>(null, Validators.required),
            }),
            search: new FormGroup({
                companyName: new FormControl<string>(null, [Validators.required, Validators.minLength(2), Validators.maxLength(100)]),
                city: new FormControl<string>(null, [Validators.required, Validators.minLength(2), Validators.maxLength(40)]),
                country: new FormControl<string>({ value: 'Deutschland', disabled: true }, [Validators.required, Validators.minLength(2), Validators.maxLength(8)]),
            }),
            lessee: new FormControl(null, [Validators.required]),
        });

        // we need to subscribe to the form changes to calculate the rate values.
        // only when the whole 'data' object is valid, the we can calculate the rates.
        this._form.controls.calculation.controls.data.valueChanges.pipe(
            debounceTime(Globals.Input.DELAY),
            distinctUntilChanged(),
        ).subscribe(() => {
            if (this._form.controls.calculation.controls.data.valid) {
                this.calculate();
            }
        });

        // when the total leasing value changes and/or the object groups change we need to recalculate the insurance value
        combineLatest([
            this._form.controls.calculation.controls.data.controls.totalLeasingValue.valueChanges,
        ]).pipe(
            debounceTime(Globals.Input.DELAY),
            distinctUntilChanged(),
        ).subscribe(() => {
            if (this._form.controls.calculation.controls.data.controls.totalLeasingValue.valid
                && this._form.controls.calculation.controls.data.controls.objectGroups.valid) {
                this.calculateInsurance();
            }
        });

        // when the total leasing value changed we need to reset the residual value and residual value in percent
        this._form.controls.calculation.controls.data.controls.totalLeasingValue.valueChanges.subscribe(() => {
            if (this._form.controls.calculation.controls.data.controls.contractType.value === 'TA') {
                if (this._residualDissolvedFactorSubject.value === 'currency') {
                    const model = (this._form.controls.calculation.controls.data.controls.totalLeasingValue.value ?? 0);
                    const percent = getPercent(model, this._form.controls.calculation.controls.data.controls.residualValue.value, 2, true);

                    this._form.controls.calculation.controls.data.controls.residualValueInPercent.patchValue(Number.isFinite(percent) ? percent : null, { emitEvent: false });
                    this._form.controls.calculation.controls.data.controls.residualValueInPercent.updateValueAndValidity({ emitEvent: false });

                    const minValue = getPercentageValue(10, this._form.controls.calculation.controls.data.controls.totalLeasingValue.value);
                    const maxValue = getPercentageValue(99, this._form.controls.calculation.controls.data.controls.totalLeasingValue.value);

                    this._minAndMaxResidualValuesSubject.next({ min: minValue, max: maxValue });
                }

                if (this._residualDissolvedFactorSubject.value === 'percent') {
                    const value = getPercentageValue(this._form.controls.calculation.controls.data.controls.residualValueInPercent.value, this._form.controls.calculation.controls.data.controls.totalLeasingValue.value);

                    this._form.controls.calculation.controls.data.controls.residualValue.patchValue(value, { emitEvent: false });
                    this._form.controls.calculation.controls.data.controls.residualValue.updateValueAndValidity({ emitEvent: false });

                    const minValue = getPercentageValue(10, this._form.controls.calculation.controls.data.controls.totalLeasingValue.value);
                    const maxValue = getPercentageValue(99, this._form.controls.calculation.controls.data.controls.totalLeasingValue.value);

                    this._minAndMaxResidualValuesSubject.next({ min: minValue, max: maxValue });
                }
            }
        });

        // when the object groups change we need to check if the insurance is required
        this._form.controls.calculation.controls.data.controls.objectGroups.valueChanges.pipe(
            debounceTime(Globals.Input.DELAY),
            distinctUntilChanged(),
        ).subscribe(() => {
            if (this._form.controls.calculation.controls.data.controls.objectGroups.value?.monthlyInsuranceFactor) {
                this._form.controls.calculation.controls.data.controls.expert.controls.insurance.patchValue(true, { emitEvent: false });
                this._form.controls.calculation.controls.data.controls.expert.controls.insurance.enable();
                this.addValidatorRequiredToFormControl(this._form.controls.calculation.controls.data.controls.expert.controls.insurance);
                this.addValidatorRequiredToFormControl(this._form.controls.calculation.controls.data.controls.expert.controls.insuranceValue);
            } else {
                this._form.controls.calculation.controls.data.controls.expert.controls.insurance.patchValue(false, { emitEvent: false });
                this._form.controls.calculation.controls.data.controls.expert.controls.insurance.disable({ emitEvent: false });
                this.removeValidatorRequiredToFormControl(this._form.controls.calculation.controls.data.controls.expert.controls.insurance);
                this.removeValidatorRequiredToFormControl(this._form.controls.calculation.controls.data.controls.expert.controls.insuranceValue);
            }
            if (this._form.controls.calculation.controls.data.controls.totalLeasingValue.valid
                && this._form.controls.calculation.controls.data.controls.objectGroups.valid) {
                this.calculateInsurance();
            }
        });

        // when the object condition changes we need to check if the exclusion of warranty is required
        this._form.controls.calculation.controls.data.controls.condition.valueChanges.subscribe(() => {
            if (this._form.controls.calculation.controls.data.controls.condition.value === IObjectConditionDto.Used) {
                this.addValidatorRequiredToFormControl(this._form.controls.calculation.controls.exclusionOfWarranty);
                this._showExclusionOfWarrantySubject.next(true);
            } else if (this._form.controls.calculation.controls.data.controls.condition.value === IObjectConditionDto.New) {
                this.removeValidatorRequiredToFormControl(this._form.controls.calculation.controls.exclusionOfWarranty);
                this._showExclusionOfWarrantySubject.next(false);
            }
        });

        // when the contract type changes we need to check if the residual value is required
        this._form.controls.calculation.controls.data.controls.contractType.valueChanges.subscribe(() => {
            if (this._form.controls.calculation.controls.data.controls.contractType.value === IContractTypeDto.Ta) {
                this._form.controls.calculation.controls.data.controls.residualValue.addValidators(Validators.required);
                this._form.controls.calculation.controls.data.controls.residualValue.addValidators(QuoteValidators.allowedValuesRelatedToLeasingValue(0.99, 0.1, 'residualValue'));
                this._form.controls.calculation.controls.data.controls.residualValueInPercent.addValidators(Validators.required);
                this._form.controls.calculation.controls.data.controls.residualValueInPercent.addValidators(CoreValidators.isNumberInRange(0.1, 0.99, 100));
                this._showResidualValuesSubject.next(true);
            } else {
                this._form.controls.calculation.controls.data.controls.residualValue.clearValidators();
                this._form.controls.calculation.controls.data.controls.residualValue.updateValueAndValidity({ emitEvent: false });
                this._form.controls.calculation.controls.data.controls.residualValueInPercent.clearValidators();
                this._form.controls.calculation.controls.data.controls.residualValueInPercent.updateValueAndValidity({ emitEvent: false });
                this._showResidualValuesSubject.next(false);
            }
        });

        // when the dealer commission changes we need to check if the dealer commission in percent is required
        this._form.controls.calculation.controls.data.controls.expert.controls.dealerCommission.valueChanges.subscribe(() => {
            if (this._form.controls.calculation.controls.data.controls.expert.controls.dealerCommission.value) {
                this._form.controls.calculation.controls.data.controls.expert.controls.dealerCommissionInPercent.enable();
                this.addValidatorRequiredToFormControl(this._form.controls.calculation.controls.data.controls.expert.controls.dealerCommissionInPercent);
            } else {
                this._form.controls.calculation.controls.data.controls.expert.controls.dealerCommissionInPercent.disable();
                this.removeValidatorRequiredToFormControl(this._form.controls.calculation.controls.data.controls.expert.controls.dealerCommissionInPercent);
            }
        });

        // when the down payment checkbox changes we need to show/hide the down payment fields and set the validators
        this._form.controls.calculation.controls.data.controls.downPaymentCheckBox.valueChanges.subscribe((x) => {
            if (x) {
                this._form.controls.calculation.controls.data.controls.downPayment.addValidators(QuoteValidators.allowedValuesRelatedToLeasingValue(0.3, 0.01, 'downPayment'));
                this._form.controls.calculation.controls.data.controls.downPaymentInPercent.addValidators(CoreValidators.isNumberInRange(0.01, 0.3, 100));

                this.addValidatorRequiredToFormControl(this._form.controls.calculation.controls.data.controls.downPayment);
                this.addValidatorRequiredToFormControl(this._form.controls.calculation.controls.data.controls.downPaymentInPercent);
                this._hasDownPaymentSubject.next(true);
            } else {
                this._form.controls.calculation.controls.data.controls.downPayment.clearValidators();
                this._form.controls.calculation.controls.data.controls.downPaymentInPercent.clearValidators();
                this.removeValidatorRequiredToFormControl(this._form.controls.calculation.controls.data.controls.downPayment);
                this.removeValidatorRequiredToFormControl(this._form.controls.calculation.controls.data.controls.downPaymentInPercent);
                this._hasDownPaymentSubject.next(false);
            }
        });

        // when the down payment in euro changes we need calculate the down payment in percent
        this._form.controls.calculation.controls.data.controls.downPayment.valueChanges.subscribe(() => {
            const value = getPercent(this._form.controls.calculation.controls.data.controls.totalLeasingValue.value, this._form.controls.calculation.controls.data.controls.downPayment.value, 2, true);
            this._form.controls.calculation.controls.data.controls.downPaymentInPercent.patchValue(value, { emitEvent: false });
            this._form.controls.calculation.controls.data.controls.downPayment.updateValueAndValidity({ emitEvent: false });
            this._form.controls.calculation.controls.data.controls.downPaymentInPercent.updateValueAndValidity({ emitEvent: false });
            const minValue = getPercentageValue(1, this._form.controls.calculation.controls.data.controls.totalLeasingValue.value);
            const maxValue = getPercentageValue(30, this._form.controls.calculation.controls.data.controls.totalLeasingValue.value);
            this._minAndMaxDownPaymentValuesSubject.next({ min: minValue, max: maxValue });
        });

        // when the down payment in percent changes we need calculate the down payment in euro
        this._form.controls.calculation.controls.data.controls.downPaymentInPercent.valueChanges.subscribe(() => {
            const value = getPercentageValue(this._form.controls.calculation.controls.data.controls.downPaymentInPercent.value, this._form.controls.calculation.controls.data.controls.totalLeasingValue.value);
            this._form.controls.calculation.controls.data.controls.downPayment.patchValue(value, { emitEvent: false });
            this._form.controls.calculation.controls.data.controls.downPayment.updateValueAndValidity({ emitEvent: false });
            this._form.controls.calculation.controls.data.controls.downPaymentInPercent.updateValueAndValidity({ emitEvent: false });
            const minValue = getPercentageValue(1, this._form.controls.calculation.controls.data.controls.totalLeasingValue.value);
            const maxValue = getPercentageValue(30, this._form.controls.calculation.controls.data.controls.totalLeasingValue.value);
            this._minAndMaxDownPaymentValuesSubject.next({ min: minValue, max: maxValue });
        });

        // when the residual value in euro changes we need calculate the residual value in percent
        this._form.controls.calculation.controls.data.controls.residualValue.valueChanges.subscribe(() => {
            // this is needed as signal witch field was the last one to change
            this._residualDissolvedFactorSubject.next('currency');

            const model = (this._form.controls.calculation.controls.data.controls.totalLeasingValue.value ?? 0);
            const percent = getPercent(model, this._form.controls.calculation.controls.data.controls.residualValue.value, 2, true);

            this._form.controls.calculation.controls.data.controls.residualValueInPercent.patchValue(Number.isFinite(percent) ? percent : null, { emitEvent: false });
            this._form.controls.calculation.controls.data.controls.residualValueInPercent.updateValueAndValidity({ emitEvent: false });

            const minValue = getPercentageValue(10, this._form.controls.calculation.controls.data.controls.totalLeasingValue.value);
            const maxValue = getPercentageValue(99, this._form.controls.calculation.controls.data.controls.totalLeasingValue.value);

            this._minAndMaxResidualValuesSubject.next({ min: minValue, max: maxValue });
        });

        // when the residual value in percent changes we need calculate the residual value in euro
        this._form.controls.calculation.controls.data.controls.residualValueInPercent.valueChanges.subscribe(() => {
            // this is needed as signal witch field was the last one to change
            this._residualDissolvedFactorSubject.next('percent');

            const value = getPercentageValue(this._form.controls.calculation.controls.data.controls.residualValueInPercent.value, this._form.controls.calculation.controls.data.controls.totalLeasingValue.value);

            this._form.controls.calculation.controls.data.controls.residualValue.patchValue(value, { emitEvent: false });
            this._form.controls.calculation.controls.data.controls.residualValue.updateValueAndValidity({ emitEvent: false });

            const minValue = getPercentageValue(10, this._form.controls.calculation.controls.data.controls.totalLeasingValue.value);
            const maxValue = getPercentageValue(99, this._form.controls.calculation.controls.data.controls.totalLeasingValue.value);

            this._minAndMaxResidualValuesSubject.next({ min: minValue, max: maxValue });
        });

        this._form.controls.lessee.valueChanges.subscribe((lessee) => {
            this._busyBoxService
                .show(null, null, this._contactService.checkContactNavStateByType({
                    id: lessee.at(0).crefoId,
                    contactType: 'lessee',
                    idType: IIdTypeDto.Crefo,
                }))
                .subscribe((x) => {
                    if (x.contactNo.length) {
                        this.checkIfNewContactIsNeeded(lessee.at(0));
                    } else {
                        this._form.controls.lessee.patchValue([objectToSnake(lessee) as any], { emitEvent: false });
                        this.createQuote().subscribe();
                    }
                }, (error) => {
                    // customer is protected
                    if (error instanceof HttpErrorResponse && error.status === 409 && error.error.error === 'contact_customer_protected') {
                        const message = this._translationFacade.instant('dialogs.contact_customer_protected');
                        this._messageBoxService.show('Bestehender Kundenschutz', message, MessageBoxButton.OK, {
                            labels: { yes: this._translationFacade.translate('global.close') },
                        }).subscribe((result) => {
                            if (result === MessageBoxResult.Yes) {
                                this._form.controls.lessee.patchValue([objectToSnake(lessee) as any], { emitEvent: false });
                                this.createQuote().subscribe();
                            }
                        });
                    }

                    if (error instanceof HttpErrorResponse && error.status === 409 && error.error.error === 'contact_customer_reserved') {
                        const message = this._translationFacade.instant('dialogs.contact_customer_reserved');
                        this._messageBoxService.show('Bestehende Reservierung für Kundenschutz', message, MessageBoxButton.YesNo, {
                            labels: {
                                yes: this._translationFacade.translate('global.further'),
                                no: this._translationFacade.translate('global.cancel'),
                            },
                        }).subscribe((result) => {
                            if (result === MessageBoxResult.Yes) {
                                this.checkIfNewContactIsNeeded(lessee.at(0));
                            } else {
                                this._form.controls.lessee.patchValue(null, { emitEvent: false });
                            }
                        });
                    }
                });
        });
    }

    private calculate(): void {
        let downPaymentInPercent = null;
        let residualValueInPercent = null;

        if (this._form.controls.calculation.controls.data.controls.downPaymentCheckBox.value) {
            downPaymentInPercent = this.calculatePercentageValue('downPayment');
        }

        if (this._form.controls.calculation.controls.data.controls.contractType.value === IContractTypeDto.Ta) {
            residualValueInPercent = this.calculatePercentageValue('residualValue');
        }

        const calculationDetails: IRetailerCalculationRequestDto = {
            totalLeasingValue: this._form.controls.calculation.controls.data.controls.totalLeasingValue.value,
            contractType: this._form.controls.calculation.controls.data.controls.contractType.value,
            objectGroupCode: Number(this._form.controls.calculation.controls.data.controls.objectGroups.value.code),
            objectCondition: this._form.controls.calculation.controls.data.controls.condition.value,
            residualValue: this._form.controls.calculation.controls.data.controls.contractType.value === IContractTypeDto.Ta ? this._form.controls.calculation.controls.data.controls.residualValue.value : null,
            residualValueInPercent: residualValueInPercent,
            downPayment: this._form.controls.calculation.controls.data.controls.downPaymentCheckBox.value ? this._form.controls.calculation.controls.data.controls.downPayment.value : null,
            downPaymentInPercent: downPaymentInPercent,
            monthlyInsurance: this._form.controls.calculation.controls.data.controls.expert.controls.insurance.value,
            handlingFee: this._form.controls.calculation.controls.data.controls.expert.controls.handlingFee.value,
            dealerCommissionInPercent: this._form.controls.calculation.controls.data.controls.expert.controls.dealerCommission.value ? this._form.controls.calculation.controls.data.controls.expert.controls.dealerCommissionInPercent.value : null,
        };

        this._calculationDetails.next(calculationDetails);

        once(this._busyBoxService.show(undefined, undefined,
            this._retailerCalculationService.calculateLeasingQuote({
                body: calculationDetails,
            }),
        ), (res) => {
            this._ratesSubject.next(res.results);
            this._showRatesSubject.next(true);
            this._cdr.detectChanges();
        }, (error) => {
            let errorKey = 'quote.retailers.calculation.calculate.toast.error';
            switch (error.error.error) {
                case 'invalid_contract_type':
                    errorKey = 'quote.retailers.calculation.calculate.toast.invalid_contract_type';
                    break;
            }
            this._toastService.show(this._translationFacade.translate(errorKey), 'danger', 'long');
            throwError(error);
        });
    }

    private calculateInsurance(): void {
        let downPaymentInPercent = null;
        let residualValueInPercent = null;

        if (this._form.controls.calculation.controls.data.controls.downPaymentCheckBox.value) {
            downPaymentInPercent = this.calculatePercentageValue('downPayment');
        }

        if (this._form.controls.calculation.controls.data.controls.contractType.value === IContractTypeDto.Ta) {
            residualValueInPercent = this.calculatePercentageValue('residualValue');
        }

        const calculationDetails: IRetailerCalculationRequestDto = {
            totalLeasingValue: this._form.controls.calculation.controls.data.controls.totalLeasingValue.value,
            contractType: this._form.controls.calculation.controls.data.controls.contractType.value,
            objectGroupCode: Number(this._form.controls.calculation.controls.data.controls.objectGroups.value.code),
            objectCondition: this._form.controls.calculation.controls.data.controls.condition.value,
            residualValue: this._form.controls.calculation.controls.data.controls.contractType.value === IContractTypeDto.Ta ? this._form.controls.calculation.controls.data.controls.residualValue.value : null,
            residualValueInPercent: residualValueInPercent,
            downPayment: this._form.controls.calculation.controls.data.controls.downPaymentCheckBox.value ? this._form.controls.calculation.controls.data.controls.downPayment.value : null,
            downPaymentInPercent: downPaymentInPercent,
            monthlyInsurance: this._form.controls.calculation.controls.data.controls.expert.controls.insurance.value,
            handlingFee: this._form.controls.calculation.controls.data.controls.expert.controls.handlingFee.value,
            dealerCommissionInPercent: this._form.controls.calculation.controls.data.controls.expert.controls.dealerCommission.value ? this._form.controls.calculation.controls.data.controls.expert.controls.dealerCommissionInPercent.value : null,
        };

        once(this._busyBoxService.show(undefined, undefined,
            this._retailerCalculationService.calculateLeasingQuote({
                body: calculationDetails,
            }),
        ), (res) => {
            this._form.controls.calculation.controls.data.controls.expert.controls.insuranceValue.patchValue(res.monthlyInsuranceValue, { emitEvent: false });
            if (!res.monthlyInsuranceValue) {
                this._form.controls.calculation.controls.data.controls.expert.controls.insurance.patchValue(false, { emitEvent: false });
            }
        });
    }

    private checkIfNewContactIsNeeded(lessee: IContactSearchResultEntryDto): void {
        this._busyBoxService.show(null, null, this._contactService.checkIfNewContactNeeded({ id: lessee.crefoId, idType: IIdTypeDto.Crefo }), { id: 'checkNewContact' })
            .subscribe((res: IContactDto) => {
                if (res.new_contact_needed) {
                    const contactDto = res;
                    const name = contactDto.name;
                    const address = `${contactDto.street} ${contactDto.house_number}, ${contactDto.postcode} ${contactDto.city}`;
                    const message = this._translationFacade.instant('dialogs.new_contact_address_lessee', {
                        param1: name,
                        param2: address,
                    });
                    this._messageBoxService.show('Abweichende Adresse', message, MessageBoxButton.YesNo, {
                        labels: {
                            yes: this._translationFacade.translate('global.further'),
                            no: this._translationFacade.translate('global.cancel'),
                        },
                    }).subscribe((result) => {
                        if (result === MessageBoxResult.Yes) {
                            this._form.controls.lessee.patchValue([res as any], { emitEvent: false });
                            this.createQuote().subscribe();
                        } else {
                            this._form.controls.lessee.patchValue(null, { emitEvent: false });
                        }
                    });
                } else {
                    this._form.controls.lessee.patchValue([objectToSnake(lessee) as any], { emitEvent: false });
                    this.createQuote().subscribe();
                }
            });
    }

    private patchValuesFromQuote(quoteDetails: IRetailerQuoteResultDto): void {
        const objGroup = this._retailerObjectGroupsSubject.getValue().find(oG => oG.code === quoteDetails.quote.objects[0].objectGroup);
        const data = {
            totalLeasingValue: quoteDetails.detail.objectValue,
            contractType: quoteDetails.detail.contractType,
            objectGroups: objGroup,
            condition: quoteDetails.quote.objects[0].condition,
            residualValue: quoteDetails.quote.calculation.residualValue,
            downPaymentCheckBox: !!quoteDetails.quote.calculation.downPaymentValue,
            downPayment: quoteDetails.quote.calculation.downPaymentValue ? quoteDetails.quote.calculation.downPaymentValue : null,
            expert: {
                insurance: !!quoteDetails.quote.monthlyInsuranceValue,
                insuranceValue: quoteDetails.quote.monthlyInsuranceValue,
                handlingFee: !!quoteDetails.quote.handlingFeeValue,
                handlingFeeValue: quoteDetails.quote.handlingFeeValue,
                dealerCommission: !!quoteDetails.quote.dealerCommissionInPercent,
                dealerCommissionInPercent: quoteDetails.quote.dealerCommissionInPercent,
            },
        };

        this._form.controls.calculation.controls.data.patchValue(data);
        if (quoteDetails.detail.contractType === 'TA') {
            // this.calculateResidualValue('residualValueInPercent', null);
            this._showResidualValuesSubject.next(true);
        }

        if (quoteDetails.quote.calculation.downPaymentValue) {
            // this.calculateDownPayment('downPaymentInPercent', null);
            const evt = new MatCheckboxChange();
            evt.checked = true;
            // this.toggleDownPayment(evt);

            this._hasDownPaymentSubject.next(true);
        } else {
            this._form.controls.calculation.controls.data.controls.downPayment.patchValue(null);
            this._form.controls.calculation.controls.data.controls.downPayment.updateValueAndValidity({ emitEvent: false });
            this._form.controls.calculation.controls.data.controls.downPaymentInPercent.updateValueAndValidity({ emitEvent: false });
            this._hasDownPaymentSubject.next(false);
        }

        this.calculate();

        this._form.controls.calculation.controls.rate.setValue(null);
        this._form.controls.calculation.controls.rate.setValue(null);
        this._form.controls.calculation.controls.objectQuantity.patchValue(quoteDetails.quote.objects[0].objectQuantity);
        this._form.controls.calculation.controls.objectName.patchValue(quoteDetails.quote.objects[0].objectDescription);
        this._form.controls.calculation.controls.exclusionOfWarranty.patchValue(quoteDetails.quote.objects[0].exclusionOfWarranty);
        this._showExclusionOfWarrantySubject.next(quoteDetails.quote.objects[0].condition === IObjectConditionDto.Used);
    }

    private calculatePercentageValue(whatToCalculate: string): number {
        let ofValue = this._form.controls.calculation.controls.data.controls.downPayment.value;
        const fromValue = this._form.controls.calculation.controls.data.controls.totalLeasingValue.value;
        switch (whatToCalculate) {
            case 'downPayment':
                ofValue = this._form.controls.calculation.controls.data.controls.downPayment.value;
                break;
            case 'residualValue':
                ofValue = this._form.controls.calculation.controls.data.controls.residualValue.value;
                break;
        }

        return toDecimal(((ofValue * 100) / fromValue), 10, true);
    }

    private addValidatorRequiredToFormControl(ctrl: AbstractControl): void {
        ctrl.addValidators(Validators.required);
        ctrl.updateValueAndValidity({ emitEvent: false });
    }

    private removeValidatorRequiredToFormControl(ctrl: AbstractControl): void {
        ctrl.removeValidators(Validators.required);
        ctrl.updateValueAndValidity({ emitEvent: false });
    }

    // #endregion
}
