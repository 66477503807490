// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-icon ::ng-deep .mat-badge-content {
  background: #ffb500;
  color: #b81931;
  width: 18px;
  height: 18px;
  top: 0px;
  right: -8px !important;
  line-height: 17px;
  font-size: 12px;
  text-align: center;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImJvbmktY2hlY2staWNvbi5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDSTtFQUNJLG1CQUFBO0VBQ0EsY0FBQTtFQUNBLFdBQUE7RUFDQSxZQUFBO0VBQ0EsUUFBQTtFQUNBLHNCQUFBO0VBQ0EsaUJBQUE7RUFDQSxlQUFBO0VBQ0Esa0JBQUE7QUFBUiIsImZpbGUiOiJib25pLWNoZWNrLWljb24uY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIubWF0LWljb24gOjpuZy1kZWVwIHtcbiAgICAubWF0LWJhZGdlLWNvbnRlbnQge1xuICAgICAgICBiYWNrZ3JvdW5kOiAjZmZiNTAwO1xuICAgICAgICBjb2xvcjogI2I4MTkzMTtcbiAgICAgICAgd2lkdGg6IDE4cHg7XG4gICAgICAgIGhlaWdodDogMThweDtcbiAgICAgICAgdG9wOiAwcHg7XG4gICAgICAgIHJpZ2h0OiAtOHB4ICFpbXBvcnRhbnQ7XG4gICAgICAgIGxpbmUtaGVpZ2h0OiAxN3B4O1xuICAgICAgICBmb250LXNpemU6IDEycHg7XG4gICAgICAgIHRleHQtYWxpZ246IGNlbnRlcjtcbiAgICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/presentation/src/Components/boni-check-icon/boni-check-icon.component.scss"],"names":[],"mappings":"AACI;EACI,mBAAA;EACA,cAAA;EACA,WAAA;EACA,YAAA;EACA,QAAA;EACA,sBAAA;EACA,iBAAA;EACA,eAAA;EACA,kBAAA;AAAR;AACA,wwBAAwwB","sourcesContent":[".mat-icon ::ng-deep {\n    .mat-badge-content {\n        background: #ffb500;\n        color: #b81931;\n        width: 18px;\n        height: 18px;\n        top: 0px;\n        right: -8px !important;\n        line-height: 17px;\n        font-size: 12px;\n        text-align: center;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
