//#region Imports

import {
    ChangeDetectionStrategy,
    Component,
    Input, OnInit
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ActivatedRoute, Router } from '@angular/router';
import {
    CONTRACT_MANAGEMENT_RETAILER_CONTRACT_DETAILS_ROUTE_PATH
} from '../../../../contractManagement/src/Routing/RoutePaths';
import { QUOTE_RETAILER_QUOTE_DETAILS_ROUTE_PATH } from '../../../../quote/src/Routing/RoutePaths';
import { combineLatest } from 'rxjs';
import { IContactDto } from '@abcfinlab/api/contact';
import { IRetailerQuoteResultDto } from '@abcfinlab/api/global';

//#endregion

/**
 * The `RetailerContractSuccessView` view.
 *
 * @public
 */
@UntilDestroy()
@Component({
    selector: 'l7-retailer-contract-success-view',
    templateUrl: './RetailerContractSuccessView.html',
    styleUrls: ['./RetailerContractSuccessView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RetailerContractSuccessView implements OnInit{

    //#region Fields

    private readonly _router: Router;

    private readonly _activeRoute: ActivatedRoute;

    private _successType: 'i' | 'q' | 'c' | 'rq' | 'rc' | 'ra';

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `RetailerContractSuccessView` class.
     *
     * @public
     */
    public constructor(router: Router, activeRoute: ActivatedRoute) {
        this._router = router;
        this._activeRoute = activeRoute;
    }

    @Input() contractNo: string;

    @Input() quoteId: string;

    @Input() showButton: boolean;

    public headerText: string = 'quote.retailers.quoteDetails.tasks.createContract.successHeaderText';
    public successText: string = 'quote.retailers.quoteDetails.tasks.createContract.successText';

    public buttonText: string = 'quote.retailers.quoteDetails.tasks.createContract.buttonTextContract';

    public lessee: IContactDto;

    public quote: IRetailerQuoteResultDto;

    public subTitle: string = '';

    public ngOnInit() {
        combineLatest([this._activeRoute.params, this._activeRoute.data, this._activeRoute.queryParams])
            .subscribe(([params, data, queryParams]) => {
                this._successType = queryParams.type;
                switch (queryParams.type) {
                    // identification completed after contract signing
                    case 'i':
                        this.headerText = 'quote.retailers.quoteDetails.tasks.createContract.successHeaderTextIdentification';
                        this.successText = 'quote.retailers.quoteDetails.tasks.createContract.successTextIdentification';
                        this.buttonText = 'quote.retailers.quoteDetails.tasks.createContract.buttonTextContract';
                        this.showButton = true;
                        this.subTitle = `Vertragsnr.: ${params.id}`;
                        this.contractNo = params.id;
                        break;
                    // contract signing completed but not identification
                    case 'q':
                        this.headerText = 'Super';
                        this.successText = 'quote.retailers.quoteDetails.tasks.createContract.successTextWithoutIdentification';
                        this.buttonText = 'quote.retailers.quoteDetails.tasks.createContract.buttonTextQuote';
                        this.showButton = true;
                        this.subTitle = `Angebotsnummer: ${data.quote.quote.name}`;
                        this.quoteId = params.id;
                        break;
                    // contract signing done after identification completed
                    case 'c':
                        this.headerText = 'quote.retailers.quoteDetails.tasks.createContract.successHeaderText';
                        this.successText = 'quote.retailers.quoteDetails.tasks.createContract.successText';
                        this.buttonText = 'quote.retailers.quoteDetails.tasks.createContract.buttonTextContract';
                        this.showButton = true;
                        this.subTitle = `Vertragsnr.: ${params.id}`;
                        this.contractNo = params.id;
                        break;
                    // remote contract signing completed but not identification
                    case 'rq':
                        this.headerText = 'Super';
                        this.successText = 'quote.retailers.quoteDetails.tasks.createContract.successTextWithoutIdentification';
                        this.subTitle = `Angebotsnummer: ${data.quote.quote.name}`;
                        break;
                    // remote contract signing done after identification completed
                    case 'rc':
                        this.headerText = 'quote.retailers.quoteDetails.tasks.createContract.successHeaderText';
                        this.successText = 'quote.retailers.quoteDetails.tasks.createContract.successText';
                        this.subTitle = `Vertragsnr.: ${params.id}`;
                        break;
                    // remote acknowledgement
                    case 'ra':
                        this.headerText = 'quote.retailers.quoteDetails.tasks.createContract.successHeaderText';
                        this.successText = 'remote.acknowledgement.retailer_sign_success';
                        break;
                }

                if (data.lessee) {
                    this.lessee = data.lessee;
                }
                if (data.quote) {
                    this.quote = data.quote;
                }
            });
    }

    public goToContract(): void {
        if (this._successType === 'q') {
            void this._router.navigateByUrl(`presentation/${QUOTE_RETAILER_QUOTE_DETAILS_ROUTE_PATH}/${this.quoteId}`);
        } else {
            void this._router.navigateByUrl(`presentation/${CONTRACT_MANAGEMENT_RETAILER_CONTRACT_DETAILS_ROUTE_PATH}/${this.contractNo}`);
        }
    }
}
