import { IContactDto } from '@abcfinlab/api/contact';
import {
    IbanCheckService,
    IBankAccountDto,
    IContractDeliveryTypeDto,
    ILeasingQuoteConfirmationTypeDto,
    IRetailerBankAccountDto,
    IRetailerCreateContractRequestDto,
    IRetailerQuoteResultDto, IRetailerSignerDto, RemoteIbanCheckService,
    RetailerQuoteWorkflowService, RetailerRemoteQuoteService,
    VerificationService,
} from '@abcfinlab/api/global';
import { ControlsOf, Validators as CoreValidators, FormValidator, once, TranslationFacade } from '@abcfinlab/core';
import { BusyBoxService, MessageBoxService, ToastService } from '@abcfinlab/ui';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatRadioChange } from '@angular/material/radio';
import { BehaviorSubject, Observable } from 'rxjs';
import {
    IBankAccountFormDto,
} from '../../../../quote/src/Views/Dialogs/retailer-identification/RetailerIdentificationViewPresenter';

// #endregion

/**
 * The presenter of the {@link RetailerSignContractView} view.
 *
 * @internal
 */
@Injectable()
export class RetailerSignContractViewPresenter {
    // #region Fields
    private readonly _ibanCheckService: IbanCheckService;
    private readonly _remoteIbanChecker: RemoteIbanCheckService;
    private readonly _retailerQuoteWorkFlowService: RetailerQuoteWorkflowService;
    private readonly _retailerRemoteQuoteService: RetailerRemoteQuoteService;
    private readonly _form: FormGroup<ControlsOf<IRetailerCreateContractRequestDto>>;
    private readonly _messageBoxService: MessageBoxService;
    private readonly _translationFacade: TranslationFacade;
    private readonly _busyBoxService: BusyBoxService;
    private readonly _toastService: ToastService;
    private readonly _formValidator: FormValidator;
    private readonly _quoteDetails: BehaviorSubject<IRetailerQuoteResultDto> = new BehaviorSubject<IRetailerQuoteResultDto>(null);
    private readonly _lessee: BehaviorSubject<IContactDto> = new BehaviorSubject<IContactDto>(null);
    private readonly _bankAccount: BehaviorSubject<IBankAccountDto> = new BehaviorSubject<IBankAccountDto>(null);
    private readonly _signingCompleted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly _signingContractHasError: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly _confirmationsInvalid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    private readonly _isFormValid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly _isSoftware: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly _id: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private _lesseeId: string;
    private readonly _paymentType: BehaviorSubject<'BANK_ACCOUNT' | 'SELF_PAYER'> = new BehaviorSubject<'BANK_ACCOUNT' | 'SELF_PAYER'>('BANK_ACCOUNT');
    private _type: 'LOCAL' | 'REMOTE' | 'REMOTE_WITHOUT_IDENTIFICATION' | 'LOCAL_WITHOUT_IDENTIFICATION' = 'LOCAL';
    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the `RetailerSignContractViewPresenter` class.
     *
     * @public
     */
    public constructor(messageBoxService: MessageBoxService, translationFacade: TranslationFacade,
        busyBoxService: BusyBoxService, toastService: ToastService, verificationService: VerificationService,
        retailerQuoteWorkFlowService: RetailerQuoteWorkflowService, ibanCheckService: IbanCheckService,
        retailerRemoteQuoteService: RetailerRemoteQuoteService, remoteIbanChecker: RemoteIbanCheckService, formValidator: FormValidator) {
        this._retailerQuoteWorkFlowService = retailerQuoteWorkFlowService;
        this._retailerRemoteQuoteService = retailerRemoteQuoteService;
        this._ibanCheckService = ibanCheckService;
        this._remoteIbanChecker = remoteIbanChecker;
        this._messageBoxService = messageBoxService;
        this._translationFacade = translationFacade;
        this._toastService = toastService;
        this._busyBoxService = busyBoxService;
        this._formValidator = formValidator;

        this._form = new FormGroup<ControlsOf<IRetailerCreateContractRequestDto>>({
            signer: new FormGroup<ControlsOf<IRetailerSignerDto>>({
                emailAddress: new FormControl('', [Validators.required, Validators.email]),
                lastName: new FormControl('', Validators.required),
                firstName: new FormControl('', Validators.required),
            }),
            city: new FormControl('', [Validators.required, Validators.minLength(2)]),
            confirmationTypes: new FormControl<Array<ILeasingQuoteConfirmationTypeDto>>([], Validators.requiredTrue),
            deliveryType: new FormControl<IContractDeliveryTypeDto>(IContractDeliveryTypeDto.Email),
            bankAccount: new FormGroup<ControlsOf<IRetailerBankAccountDto>>({
                bankAccountName: new FormControl<string>(null, Validators.required),
                bankAccountType: new FormControl('DIRECT_DEBIT'),
                bankName: new FormControl(''),
                bic: new FormControl(''),
                iban: new FormControl(null, [Validators.required]),
            }),
        });
    }

    // #endregion

    // #region Properties
    public readonly signatureFile: BehaviorSubject<Blob> = new BehaviorSubject<Blob>(null);

    /**
     * Returns the `quoteDetails` property.
     *
     * @public
     * @readonly
     */
    public get quoteDetails(): Observable<IRetailerQuoteResultDto> {
        return this._quoteDetails.asObservable();
    }

    /**
     * Returns the `id` property.
     *
     * @public
     * @readonly
     */
    public get id(): Observable<string> {
        return this._id.asObservable();
    }

    // #region Properties
    /**
     * Returns the `lessee` property.
     *
     * @public
     * @readonly
     */
    public get lessee(): Observable<IContactDto> {
        return this._lessee.asObservable();
    }

    /**
     * Returns the `bankAccount` property.
     *
     * @public
     * @readonly
     */
    public get bankAccount(): Observable<IBankAccountDto> {
        return this._bankAccount.asObservable();
    }

    /**
     * Returns the `signingCompleted` property.
     *
     * @public
     * @readonly
     */
    public get signingCompleted(): Observable<boolean> {
        return this._signingCompleted.asObservable();
    }

    /**
     * Returns the `signingContractHasError` property.
     *
     * @public
     * @readonly
     */
    public get signingContractHasError(): Observable<boolean> {
        return this._signingContractHasError.asObservable();
    }

    /**
     * Returns the `confirmationsInvalid` property.
     *
     * @public
     * @readonly
     */
    public get confirmationsInvalid(): Observable<boolean> {
        return this._confirmationsInvalid.asObservable();
    }

    /**
     * Returns the `isFormValid` property.
     *
     * @public
     * @readonly
     */
    public get isFormValid(): Observable<boolean> {
        return this._isFormValid.asObservable();
    }

    /**
     * Returns the `isSoftware` property.
     *
     * @public
     * @readonly
     */
    public get isSoftware(): Observable<boolean> {
        return this._isSoftware.asObservable();
    }

    /**
     * Returns the `form` property.
     *
     * @public
     * @readonly
     */
    public get form(): FormGroup<ControlsOf<IRetailerCreateContractRequestDto>> {
        return this._form;
    }

    /**
     * Returns the `paymentType` property.
     *
     * @public
     * @readonly
     */
    public get paymentType(): Observable<'BANK_ACCOUNT' | 'SELF_PAYER'> {
        return this._paymentType.asObservable();
    }

    // #endregion

    // #region Methods

    /**
     * Called before the view first displays the data-bound properties and sets the view's input properties.
     *
     * @internal
     */
    public initialize(quoteDetails: IRetailerQuoteResultDto, lessee: IContactDto, id: string, type: 'LOCAL' | 'REMOTE' | 'REMOTE_WITHOUT_IDENTIFICATION' | 'LOCAL_WITHOUT_IDENTIFICATION'): void {
        this._type = type;
        const bankAccount: IRetailerBankAccountDto = quoteDetails.workflow.workflowSteps.find(step => step.stepType === 'CREATE_CONTRACT').metaInformation.bankAccount as IRetailerBankAccountDto;
        this._quoteDetails.next(quoteDetails);
        this._lessee.next(lessee);
        this._lesseeId = quoteDetails?.quote?.lesseeId;
        this._id.next(id);
        this.form.get('bankAccount.iban').patchValue(bankAccount?.iban);
        this.form.get('bankAccount.bankAccountName').patchValue(bankAccount?.bankAccountName);

        if (quoteDetails.quote.objects[0].objectGroup === 5230 || quoteDetails.quote.objects[0].objectGroup === 5210) {
            this._isSoftware.next(true);
        }

        if (quoteDetails.quote.signer) {
            this._form.controls.signer.patchValue(quoteDetails.quote.signer);
        }

        if (bankAccount) {
            this._bankAccount.next({
                iban: bankAccount.iban,
                bank_name: bankAccount.bankName,
                bic: bankAccount.bic,
                bank_account_name: bankAccount.bankAccountName,
                bank_account_type: 'DIRECT_DEBIT',
            });
            this._form.get('bankAccount.iban').markAsTouched();
            this._form.get('bankAccount.bankName').patchValue(bankAccount.bankName);
            this._form.get('bankAccount.bic').patchValue(bankAccount.bic);
        }
        this.addAsyncValidatorForIban();
        this._form.valueChanges
            .subscribe((_) => {
                this.setIsFormValid();
            });
    }

    /**
     * Called before the view will be destroyed.
     * Unsubscribe Observables and detach event handlers to avoid memory leaks.
     *
     * @internal
     */
    public dispose(): void {
        //
    }

    public updateSigner(signer: IRetailerSignerDto) {
        this._form.controls.signer.patchValue(signer);
    }

    public updateBankAccount(bankAccount: IBankAccountFormDto): void {
        this._form.controls.bankAccount.patchValue(bankAccount);
    }

    /**
     * @internal
     */
    public onSubmit(): void {
        this._formValidator.validate(this.form);
        if (this._isFormValid.getValue() && !this._confirmationsInvalid.getValue() && this.signatureFile.getValue()) {
            let retailerCreateContractRequest = this._form.getRawValue();
            if (this._paymentType.getValue() === 'SELF_PAYER') {
                retailerCreateContractRequest = {
                    ...this._form.getRawValue(),
                    ...{ bankAccount: null },
                };
            }
            if (this._type === 'LOCAL' || this._type === 'LOCAL_WITHOUT_IDENTIFICATION') {
                once(this._busyBoxService.show(undefined, this._translationFacade.translate('global.busy'),
                    this._retailerQuoteWorkFlowService.triggerCreateContract({
                        leasingQuoteId: this._id.getValue(),
                        body: {
                            retailerCreateContractRequest,
                            signature: this.signatureFile.getValue(),
                        },
                    })), res => this._signingCompleted.next(true),
                (error) => {
                    this._toastService.show(this._translationFacade.instant('error.generic_error'), 'danger');
                    this._signingContractHasError.next(true);
                });
            } else {
                once(this._busyBoxService.show(undefined, this._translationFacade.translate('global.busy'),
                    this._retailerRemoteQuoteService.triggerCreateContractRemote({
                        action: this._id.getValue(),
                        body: {
                            retailerCreateContractRequest,
                            signature: this.signatureFile.getValue(),
                        },
                    })), res => this._signingCompleted.next(true),
                (error) => {
                    this._toastService.show(this._translationFacade.instant('error.generic_error'), 'danger');
                    this._signingContractHasError.next(true);
                });
            }
        }
    }

    public transformToUpperCase(evt): void {
        const value: string = evt.target.value.toUpperCase();
        this._form.get('bankAccount.iban').patchValue(value);
        this._form.get('bankAccount.iban').updateValueAndValidity({ emitEvent: true });
    }

    public handleBankAccountInfo(bankAccount: IBankAccountDto): void {
        this._bankAccount.next(bankAccount);
        this._form.get('bankAccount.bankName').patchValue(bankAccount?.bank_name);
        this._form.get('bankAccount.bic').patchValue(bankAccount?.bic);
    }

    public confirmationsChanged(evt: MatCheckboxChange): void {
        const confirmations = this._form.get('confirmationTypes').getRawValue();
        let result;
        if (evt.checked) {
            result = confirmations.push(evt.source.name);
            result = confirmations;
        } else {
            result = confirmations.filter(c => c !== evt.source.name);
        }

        this._form.get('confirmationTypes').patchValue(result);
        this.areConfirmationsValid() ? this._form.controls.confirmationTypes.setErrors(null) : this._form.controls.confirmationTypes.setErrors({ required: true });
        this._confirmationsInvalid.next(!this.areConfirmationsValid());
    }

    public paymentTypeChanged(evt: MatRadioChange): void {
        if (!(evt instanceof MatRadioChange)) {
            return;
        }
        if (evt.value === 'BANK_ACCOUNT') {
            this._form.get('bankAccount.bankAccountName').addValidators(Validators.required);
            this._form.get('bankAccount.bankAccountName').updateValueAndValidity();
            this._form.get('bankAccount.iban').addValidators(Validators.required);
            this.addAsyncValidatorForIban();
        } else if (evt.value === 'SELF_PAYER') {
            this._form.get('bankAccount.bankAccountName').removeValidators(Validators.required);
            this._form.get('bankAccount.bankAccountName').updateValueAndValidity();
            this._form.get('bankAccount.iban').removeValidators([Validators.required]);
            this.removeAsyncValidatorForIban();
        }
        this._paymentType.next(evt.value);
        this.setIsFormValid();
    }

    private areConfirmationsValid(): boolean {
        const defaultConfirmations = [ILeasingQuoteConfirmationTypeDto.Gdpr, ILeasingQuoteConfirmationTypeDto.TermsAndConditions];
        const confirmations = this._form.get('confirmationTypes').getRawValue();
        let includesDefault = confirmations.includes(ILeasingQuoteConfirmationTypeDto.Gdpr) && confirmations.includes(ILeasingQuoteConfirmationTypeDto.TermsAndConditions);
        if (this._isSoftware.getValue()) {
            defaultConfirmations.push(ILeasingQuoteConfirmationTypeDto.ContractIncludesSoftware);
            includesDefault &&= confirmations.includes(ILeasingQuoteConfirmationTypeDto.ContractIncludesSoftware);
        }

        if (this._quoteDetails.getValue().quote.objects[0].exclusionOfWarranty) {
            defaultConfirmations.push(ILeasingQuoteConfirmationTypeDto.ExclusionOfWarranty);
            includesDefault &&= confirmations.includes(ILeasingQuoteConfirmationTypeDto.ExclusionOfWarranty);
        }

        return includesDefault;
    }

    private setIsFormValid(): void {
        if (this._paymentType.getValue() === 'SELF_PAYER') {
            const isFormValid = this._form.get('city').valid;
            this._isFormValid.next(isFormValid);
        } else {
            const isFormValid = this._form.get('city').valid && this._form.get('bankAccount').valid;
            this._isFormValid.next(isFormValid);
        }
    }

    private addAsyncValidatorForIban(): void {
        if (this._type === 'LOCAL' || this._type === 'LOCAL_WITHOUT_IDENTIFICATION') {
            this._form.get('bankAccount.iban').addAsyncValidators(CoreValidators.validateIban(this._ibanCheckService));
        } else if (this._type === 'REMOTE' || this._type === 'REMOTE_WITHOUT_IDENTIFICATION') {
            this._form.get('bankAccount.iban').addAsyncValidators(CoreValidators.validateIban(this._remoteIbanChecker));
        }
        this._form.get('bankAccount.iban').updateValueAndValidity();
    }

    private removeAsyncValidatorForIban(): void {
        this._form.get('bankAccount.iban').clearAsyncValidators();
        this._form.get('bankAccount.iban').updateValueAndValidity({ emitEvent: false });
    }

    // #endregion
}
