// #region Imports

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { CalculationViewPresenter } from './CalculationViewPresenter';
import { Router } from '@angular/router';

// #endregion

/**
 * The CalculationView view.
 *
 * @public
 */
@Component({
    selector: 'l7-calculation-view',
    templateUrl: './CalculationView.html',
    styleUrls: ['./CalculationView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        CalculationViewPresenter,
    ],
})
export class CalculationView implements OnInit, OnDestroy {
    // #region Fields

    private readonly _presenter: CalculationViewPresenter;
    private readonly _router: Router;

    // #endregion

    // #region Ctor

    /**
     * Constructs a new instance of the calculation class.
     *
     * @public
     */
    public constructor(presenter: CalculationViewPresenter, router: Router) {
        this._presenter = presenter;
        this._router = router;
    }

    // #endregion

    // #region Properties

    /**
     * Returns the presenter of the calculation
     *
     * @public
     * @readonly
     */
    public get presenter(): CalculationViewPresenter {
        return this._presenter;
    }

    // #endregion

    // #region Methods

    /**
     * @internal
     */
    public ngOnInit(): void {
        this._presenter.initialize();
    }

    public goToDashboard(): void {
        void this._router.navigate(['./']);
    }

    public backToDetailView(): void {
        void this._router.navigate(['./presentation/quotes/detail']);
    }

    /**
     * @internal
     */
    public ngOnDestroy(): void {
        this._presenter.dispose();
    }

    // #endregion
}
