//#region Imports

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

//#endregion

@Injectable({ providedIn: 'root' })
export class TraceparentHttpInterceptor implements HttpInterceptor {

    //#region Ctor

    /**
     * Constructs a new instance of the `TraceparentHttpInterceptor` class.
     *
     * @public
     */
    public constructor() {
    }

    //#endregion

    //#region Methods

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        req = req.clone({
            headers: req.headers.set('Traceparent', this.randomUUID())
        });

        return next.handle(req);
    }


    /**
     * Generates a random UUID with fallback.
     *
     * @private
     */
    private randomUUID(): string {
        return 'randomUUID' in crypto
            ? crypto.randomUUID()
            : this.generateUUID();
    }

    /**
     * Generates a UUID version 4 string.
     *
     * @private
     * @returns {string} The generated UUID.
     */
    private generateUUID = (): string => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, char => {
            const rand = Math.random() * 16 | 0;
            const value = char === 'x' ? rand : (rand & 0x3 | 0x8);
            return value.toString(16);
        });
    };

    //#endregion

}
