import { ContractsDocumentService, IContractDocumentDto, IContractManagementOverviewDto, IDocumentTypeDto, IPurchaseEntryDto, IPurchaseEntryStatusDto } from '@abcfinlab/api/contract';
import { IContractManagementDetailsDto, VerificationService } from '@abcfinlab/api/global';
import { IUserInfoDto } from '@abcfinlab/api/login';
import { isUserInfoLogins, UserService } from '@abcfinlab/auth';
import { CustomFile } from '@abcfinlab/presentation';
import { BusyBoxService, MessageBoxButton, MessageBoxResult, MessageBoxService, ToastService } from '@abcfinlab/ui';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { UploadDocumentType } from '../../../../../apps/shell/src/app/models/enums/UploadDocumentType.enum';
import { GenericDialogData } from '../../../../../apps/shell/src/app/shared/modals/generic-dialog/generic-dialog.component';
import { ErrorHandlerService } from '../../../../../apps/shell/src/app/shared/services/errorHandler/error-handler.service';
import { TranslationFacade } from '../../../../core/src/Services/TranslationFacade';
import { PurchaseEntryObjectValueComponent } from '../Dialogs/purchase-entry-object-value/purchase-entry-object-value.component';

@UntilDestroy()
@Component({
    selector: 'l7-contract-purchase-entry-view',
    templateUrl: './contract-purchase-entry-view.component.html',
    styleUrls: ['./contract-purchase-entry-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContractPurchaseEntryViewComponent {
    @Input() purchaseEntry: IPurchaseEntryDto;
    @Input() contract: IContractManagementOverviewDto;

    @Input() loading: boolean;
    @Input() needsUpload: boolean;

    @Input() quoteDetails: IContractManagementDetailsDto;
    @Input() purchaseEntryDocuments: Array<IContractDocumentDto>;

    @Output() createPurchaseEntry: EventEmitter<Array<string>> = new EventEmitter<Array<string>>();
    @Output() uploadedPurchaseEntryDocuments: EventEmitter<Array<IContractDocumentDto>> = new EventEmitter<Array<IContractDocumentDto>>();
    @Output() isVendorOfferReady: EventEmitter<Array<string>> = new EventEmitter<Array<string>>();
    @Output() changeObjectValue: EventEmitter<number> = new EventEmitter<number>();

    public filesCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    public canUpload$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    private readonly _user$: Observable<IUserInfoDto>;
    private _user: IUserInfoDto;

    private _subscriptionEmailVerification: Subscription;

    purchaseEntryStatus = IPurchaseEntryStatusDto;

    purchaseEntryForm: UntypedFormGroup;

    emails: Array<string> = [];

    visible = true;

    showConfirmationModal() {
        if (this.purchaseEntryForm.valid && this.purchaseEntryForm.get('email').value !== '') {
            this.busyBoxService.show('', this._translationFacade.translate('global.busy'), this.emailVerification.verifyEmail({ emailAddress: this.purchaseEntryForm.get('email').value })
                .pipe(untilDestroyed(this)))
                .subscribe(_ => {
                    this.emails.push(this.purchaseEntryForm.get('email').value);
                    this.emitPurchaseEntry();
                },
                    _error => {
                        if (this._subscriptionEmailVerification) {
                            this._subscriptionEmailVerification.unsubscribe();
                        }
                        const dialogMessage = this._translationFacade.instant(`error.${_error.error.error}`,
                            _error.error.error_params);
                        this.errorHandler.openDialogWithIdAndBody('error.email_not_valid', _error, dialogMessage);
                        this._subscriptionEmailVerification = this.errorHandler.errorDialogEmailVerification$
                            .pipe(untilDestroyed(this))
                            .subscribe(value => {
                                if (value) {
                                    this.emails.push(this.purchaseEntryForm.get('email').value);
                                    this.emitPurchaseEntry();
                                }
                            });
                    });
        } else {
            this.emitPurchaseEntry();
        }
    }

    constructor(private readonly _fb: UntypedFormBuilder,
        private readonly _messageBoxService: MessageBoxService,
        private readonly _dialog: MatDialog,
        private readonly _translationFacade: TranslationFacade,
        private readonly errorHandler: ErrorHandlerService,
        private readonly busyBoxService: BusyBoxService,
        private readonly userService: UserService,
        private readonly emailVerification: VerificationService,
        private readonly _contractsDocumentService: ContractsDocumentService,
        private readonly _notify: ToastService,
        private readonly _cdr: ChangeDetectorRef) {
        this.purchaseEntryForm = this._fb.group({
            email: [null, [Validators.required, Validators.email]],
        });
        this._cdr.markForCheck();
        this._user$ = userService.userInfo;
        this._user$.pipe(untilDestroyed(this)).subscribe(user => {
            this._user = user;
        });
    }

    private emitPurchaseEntry() {
        const dialogMessage = this._translationFacade.instant(
            'contract_management.purchase_entry.confirmation_text',
            { param1: this.emails.toString().replace(/,/g, ', ') }
        );
        const dialogData: GenericDialogData = {
            id: 'purchase_entry_confirmation',
            title: 'contract_management.purchase_entry.confirmation_title',
            body: dialogMessage,
            negativeText: 'global.cancel',
            positiveText: 'global.confirm'
        };
        this._messageBoxService.show(this._translationFacade.instant('contract_management.purchase_entry.confirmation_title'), dialogMessage, MessageBoxButton.OKCancel, {
            labels: {
                ok: this._translationFacade.instant('global.confirm'),
                cancel: this._translationFacade.instant('global.cancel'),
            }
        }).subscribe(x => {
            if (x === MessageBoxResult.OK) {
                this.createPurchaseEntry.emit(this.emails);
            } else {
                this.emails.splice(-1, 1);
            }
        });
    }

    openChangeObjectValueDialog() {
        this._dialog.open(PurchaseEntryObjectValueComponent, {
            data: {
                quote: this.quoteDetails,
                purchase_entry: this.purchaseEntry
            },
            minHeight: '0px'
        }).afterClosed().subscribe(value => {
            if (!value.cancel) {
                this.changeObjectValue.emit(value.object_value);
            }
        });
    }
    filesToUpload(files: Array<File>): void {
        this.filesCount$.next(files.length);
        this.canUpload$.next(files.length === 1);
    }

    uploadFiles(files: Array<CustomFile>) {
        this.loading = true;
        Array.from(files).forEach((file, index) => {
            this._contractsDocumentService.uploadDocument({
                contractNumber: this.contract.contract_number,
                documentType: IDocumentTypeDto.VendorOffer,
                body: {
                    file
                }
            })
                .pipe(untilDestroyed(this))
                .subscribe(res => {
                    this.setUploadedFiles(file, res, files.length, index);
                },
                    _error => {
                        this.setUploadedFiles(file, null, files.length, index);
                    });
        });
    }

    private setUploadedFiles(file: File, _id: string, _length: number, i: number) {
        if (_id && isUserInfoLogins(this._user)) {
            this.purchaseEntryDocuments.push({
                id: _id,
                name: `${this.contract.contract_number}_Bestellung/AB/verbindl.Angebot/Preispr._${file.name}`,
                type: UploadDocumentType.VENDOR_OFFER,
                createdBy: `${this._user.loginsInfo.givenName} ${this._user.loginsInfo.familyName}`,
                creationDate: (new Date()).toDateString(),
                archiveInfo: null
            });

        } else {
            this._notify.showError('Hochladen nicht erfolgreich!');
        }
        if (_length === i + 1) {
            this.loading = false;
            this.uploadedPurchaseEntryDocuments.emit(this.purchaseEntryDocuments);
            this.isVendorOfferReady.emit([this.contract.contract_number]);
            this._cdr.detectChanges();
        }
    }
}
