//#region Imports

import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BusyBoxComponent } from './BusyBoxComponent';
import { IBusyBoxComponentData } from './IBusyBoxComponentData';
import { IBusyBoxOptions } from './IBusyBoxOptions';

//#endregion

/**
 * @public
 */
@Injectable({ providedIn: 'root' })
export class BusyBoxService {

    //#region Fields

    private readonly _dialog: MatDialog;

    private _dialogRef: MatDialogRef<unknown, unknown>;
    private _activeRequests: number;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `MessageBoxService` class.
     *
     * @public
     */
    public constructor(dialog: MatDialog) {
        this._dialog = dialog;
        this._dialogRef = null;
        this._activeRequests = 0;
    }

    //#endregion

    //#region Methods

    public show<T>(title: string | Observable<string> | null, message: string | Observable<string> | null, operation: Observable<T>, options?: IBusyBoxOptions): Observable<T> {
        if (this._activeRequests === 0) {
            const root = document.getElementsByTagName('l7-root')[0];
            root.setAttribute(`is-busy`, 'true');

            message = 'Bitte haben Sie einen Moment Geduld.';
            this._dialogRef = this._dialog.open<BusyBoxComponent, IBusyBoxComponentData>(BusyBoxComponent, {
                closeOnNavigation: true,
                disableClose: true,
                role: 'alertdialog',
                id: `${options?.id ?? ''}BusyBox`,
                width: options?.width?.value ?? '500px',
                minWidth: options?.width?.minValue,
                maxWidth: options?.width?.maxValue,
                height: options?.height?.value,
                minHeight: options?.height?.minValue,
                maxHeight: options?.height?.maxValue,

                data: {
                    title: title,
                    message: message,
                    options: options,
                },
            });


        }

        this._activeRequests++;

        return operation.pipe(
            finalize(() => {
                this._activeRequests--;

                if (this._activeRequests === 0) {
                    const root = document.getElementsByTagName('l7-root')[0];
                    root.removeAttribute('is-busy');

                    this._dialogRef.close();
                }
            }),
        );
    }

    //#endregion

}
